import React from "react"
import ReactDOM from "react-dom"
import Link from "gatsby-link"
import lottie from "lottie-web"
//import animation from "../static/animations/animation.json"

import VisibilitySensor from "react-visibility-sensor"

class Animation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      msg: "",
      visible: false,
      render: true,
      fadeOut: false,
    }
  }

  initAnimation(file = "") {
    if (this._el) {
      this._animation = lottie.loadAnimation({
        container: this._el,
        renderer: "svg",
        animationData: this.props.animation,
        loop: this.props.loop || false,
        autoplay: false,
      })
      this._animation.setSpeed(this.props.speed || 1)
      this._animation.addEventListener("complete", () => {
        if (this.props.loop) {
          this._animation.goToAndPlay(60, true)
        }
        if (this.props.playOnce) {
          this.setState({ fadeOut: true })
          setTimeout(() => this.setState({ render: false }), 300)
        }
      })
    }
  }

  init() {
    this.initAnimation()
  }
  componentDidMount() {
    this.init()
  }
  onChange = isVisible => {
    if (isVisible && isVisible !== this.state.isVisible) {
      if (this._animation) {
        this._animation.play()
      }
    }
    this.setState({
      msg: "Element is now " + (isVisible ? "visible" : "hidden"),
    })
  }

  render() {
    return (
      <div
        className={`${this.props.className} ${
          this.state.fadeOut ? "fadeOut" : ""
        }`}
        style={{
          display: this.state.render ? "block" : "none",
          position: "relative",
        }}
      >
        <VisibilitySensor onChange={this.onChange}>
          <div
            id="animation"
            ref={el => (this._el = el)}
            style={{
              position: "relative",
            }}
          />
        </VisibilitySensor>
      </div>
    )
  }
}

export default Animation
